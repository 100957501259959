import { useOutletContext } from 'react-router-dom';
import { Box, Divider, Grid, GridItem, Heading } from '@chakra-ui/react';

import MarkdownDisplay from '../../components/MarkdownDisplay';
import i18n from '../../utils/i18n';

import SummaryDemoCard from './components/SummaryDemoCard';
import { AdBoardOutletContextType } from './types';

const AdBoardExecutiveSummary = () => {
  const { executiveSummaryData } = useOutletContext<AdBoardOutletContextType>();

  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)" gap={6} mt={4}>
        <GridItem w="100%">
          <SummaryDemoCard
            title={i18n.t('adBoardDetails.executiveSummary.attendees')}
            value={executiveSummaryData.attendees}
          />
        </GridItem>
        <GridItem w="100%">
          <SummaryDemoCard
            title={i18n.t('adBoardDetails.executiveSummary.completionRate')}
            value={`${executiveSummaryData.completionRate}%`}
          />
        </GridItem>
      </Grid>
      {!!executiveSummaryData.executiveSummaryIntro && (
        <Box mt={8}>
          <MarkdownDisplay
            source={executiveSummaryData.executiveSummaryIntro}
          />
        </Box>
      )}
      {!!executiveSummaryData.executiveSummaryRecommendations && (
        <>
          <Heading mt={10} mb={2} size={'sm'}>
            {i18n.t('adBoardDetails.executiveSummary.recommendations')}
          </Heading>
          <Divider />
          <Box mt={8}>
            <MarkdownDisplay
              source={executiveSummaryData.executiveSummaryRecommendations}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default AdBoardExecutiveSummary;
export { AdBoardExecutiveSummary };
