import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Grid,
  GridItem,
  HStack,
  Select,
  Text,
} from '@chakra-ui/react';

import { KeyOpsLinkButton } from '../../components/ButtonComponents';
import SearchInputComponent from '../../components/SearchInputComponent';
import i18n from '../../utils/i18n';
import EngagementDetails from '../EngagementDetails';

import { FeedTable } from './components/FeedTable';
import { IndividualParticipantSummaryCard } from './components/IndividualParticipantSummaryCard';
import { useFilterDropdownOptions } from './hooks/useFilterDropdownOptions';
import {
  AdBoardOutletContextType,
  AdBoardSectionGroupDto,
  SectionDto,
} from './types';

const FilterDropdown = ({
  labelText,
  value,
  onChange,
  options,
}: {
  labelText: string;
  value: string | undefined;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string }[];
}) => {
  return (
    <>
      <Text fontSize="md" fontWeight={500} color="gray.600">
        {labelText}
      </Text>
      <Select
        mt={2}
        size="md"
        value={value}
        onChange={onChange}
        aria-label={labelText}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </>
  );
};

export const AdBoardIndividualParticipant = () => {
  const { adBoardData, participantsData } =
    useOutletContext<AdBoardOutletContextType>();
  const { adboardId, userId } = useParams();
  const navigate = useNavigate();

  const currentParticipant = useMemo(
    () =>
      participantsData.find((p) => p.userId.toString() === userId?.toString()),
    [participantsData, userId]
  );

  const [selectedGroup, setSelectedGroup] = useState<AdBoardSectionGroupDto>();
  const [selectedActivity, setSelectedActivity] = useState<SectionDto>();
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState('');

  const [groupOptions, activityOptions, topicOptions] =
    useFilterDropdownOptions({ adBoardData, selectedGroup, selectedActivity });

  useEffect(() => {
    if (adBoardData?.sectionGroups?.length) {
      setSelectedGroup(adBoardData.sectionGroups[0]);
    }
  }, [adBoardData?.sectionGroups]);

  useEffect(() => {
    if (selectedGroup?.sections?.length) {
      setSelectedActivity(selectedGroup.sections[0]);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedActivity?.type === 'discussion' && topicOptions.length) {
      setSelectedTopic(topicOptions[0].value);
    }
  }, [selectedActivity, topicOptions]);

  const handleSelectedGroup = (event: ChangeEvent<HTMLSelectElement>) => {
    const group = adBoardData?.sectionGroups?.find(
      (g) => g.id === event.target.value
    );
    setSelectedGroup(group);
  };

  const handleSelectedActivity = (event: ChangeEvent<HTMLSelectElement>) => {
    const activity = selectedGroup?.sections?.find(
      (section) => section.id === event.target.value
    );
    setSelectedActivity(activity);
  };

  const handleSelectedTopic = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTopic(event.target.value);
  };

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const getContent = useCallback(() => {
    if (!selectedGroup || !selectedActivity) return null;
    switch (selectedActivity?.type) {
      case 'video':
      case 'attachment':
      case 'discussion':
        return (
          <FeedTable
            selectedGroup={selectedGroup}
            selectedActivity={selectedActivity}
            selectedDiscussionId={selectedTopic}
            searchQuery={searchQuery}
            filterByUserId={userId}
          />
        );
      case 'survey':
        return (
          <Box mt={6}>
            <EngagementDetails
              adBoardSurveyEngagementId={
                selectedActivity.content.surveyEngagementId
              }
              showIndividualUserRawResultsView
            />
          </Box>
        );
      default:
        return null;
    }
  }, [searchQuery, selectedTopic, selectedActivity, selectedGroup, userId]);

  if (!currentParticipant) return <>Something went wrong</>;

  return (
    <Box mt={4}>
      <KeyOpsLinkButton
        _hover={{ textDecoration: 'none' }}
        leftIcon={<FiChevronLeft data-testid="FiChevronLeft" fontSize={22} />}
        onClick={() => navigate(`/adboard/${adboardId}/participants/`)}
      >
        {i18n.t('common.back')}
      </KeyOpsLinkButton>
      <Box
        mt={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack spacing={4}>
          <Avatar
            w="3.5rem"
            h="3.5rem"
            name={`${currentParticipant.user?.firstName} ${currentParticipant.user?.lastName}`}
            bg="keyops.blue"
          />
          <Box>
            <Text textTransform="capitalize">
              {currentParticipant.user?.firstName}{' '}
              {currentParticipant.user?.lastName}
            </Text>
            <Text fontSize="md" color="gray.500" textTransform="capitalize">
              {currentParticipant.user?.speciality?.[0] ?? ''}
            </Text>
          </Box>
        </HStack>
        <IndividualParticipantSummaryCard
          currentParticipant={currentParticipant}
        />
      </Box>
      <Grid
        templateColumns={`repeat(${
          selectedActivity?.type === 'discussion' ? 3 : 2
        }, 1fr)`}
        gap={4}
        mt={6}
      >
        <GridItem>
          <FilterDropdown
            labelText={i18n.t(
              'adBoardDetails.individualParticipant.filterBySection'
            )}
            value={selectedGroup?.id}
            onChange={handleSelectedGroup}
            options={groupOptions}
          />
        </GridItem>
        {!!selectedGroup && (
          <GridItem>
            <FilterDropdown
              labelText={i18n.t(
                'adBoardDetails.individualParticipant.filterByActivity'
              )}
              value={selectedActivity?.id}
              onChange={handleSelectedActivity}
              options={activityOptions}
            />
          </GridItem>
        )}
        {selectedActivity?.type === 'discussion' && (
          <GridItem>
            <FilterDropdown
              labelText={i18n.t(
                'adBoardDetails.individualParticipant.filterByTopic'
              )}
              value={selectedTopic}
              onChange={handleSelectedTopic}
              options={topicOptions}
            />
          </GridItem>
        )}
      </Grid>
      {selectedActivity?.type !== 'survey' && (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={6}>
          <GridItem>
            <SearchInputComponent
              placeholder={i18n.t(
                'adBoardDetails.individualParticipant.searchComments'
              )}
              value={searchQuery}
              onChange={handleSearchQueryChange}
              onClear={() => setSearchQuery('')}
            />
          </GridItem>
        </Grid>
      )}
      {getContent()}
    </Box>
  );
};
