import { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import i18n from '../../utils/i18n';
import {
  ENGAGEMENT_TAB,
  EXECUTIVE_SUMMARY_TAB,
  PARTICIPANTS_TAB,
} from '../../utils/internal-routes.const';

import { AdBoardOutletContextType } from './types';

const tabRoutes = [EXECUTIVE_SUMMARY_TAB, ENGAGEMENT_TAB, PARTICIPANTS_TAB];

const AdBoardDetails = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { adboardId } = useParams();

  const {
    adBoardEngagementDetails,
    adBoardData,
    executiveSummaryData,
    feedData,
    participantsData,
  } = useOutletContext<AdBoardOutletContextType>();

  // Extract tab index from the URL, default to 0 if not found
  const currentTabIndex = tabRoutes.findIndex((route) =>
    pathname.includes(route)
  );
  const activeTabIndex = currentTabIndex >= 0 ? currentTabIndex : 0;

  const handleTabChange = (index: number) => {
    navigate(`/adboard/${adboardId}/${tabRoutes[index]}`);
  };

  useEffect(() => {
    // Redirect to 'executive-summary' if no valid tab route is found
    if (currentTabIndex === -1) {
      navigate(`/adboard/${adboardId}/${tabRoutes[0]}`, { replace: true });
    }
  }, [currentTabIndex, adboardId, navigate]);

  return (
    <>
      <Heading as="h3" fontSize={24} fontWeight={600} textAlign="left" mt={6}>
        {adBoardEngagementDetails.displayTitle}
      </Heading>
      <Tabs index={activeTabIndex} onChange={handleTabChange} mt={6}>
        <TabList>
          <Tab>{i18n.t('adBoardDetails.executiveSummaryTab')}</Tab>
          <Tab>{i18n.t('adBoardDetails.engagementTab')}</Tab>
          <Tab>{i18n.t('adBoardDetails.participantsTab')}</Tab>
        </TabList>

        <TabPanels>
          {tabRoutes.map((_, index) => (
            <TabPanel key={index}>
              <Outlet
                context={
                  {
                    adBoardEngagementDetails,
                    adBoardData,
                    executiveSummaryData,
                    feedData,
                    participantsData,
                  } satisfies AdBoardOutletContextType
                }
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default AdBoardDetails;
